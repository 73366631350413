import StudentPaymentReceipt from "components/Reports/StudentPaymentReceipt";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Label,
  UncontrolledPopover,
  PopoverBody,
  Table,
  Spinner,
} from "reactstrap";
import "../../../assets/css/class-assistant-payments.css";
import { getLastThreeMonthsFeeByClassAssistants } from "actions/ClassAssistantLayout";
import dateFormat, { masks } from "dateformat";
import { updateStudentPaymentByClassAssistant } from "actions/ClassAssistantLayout";
import { getStudentByClassAssistants } from "actions/ClassAssistantLayout";
import { useReactToPrint } from "react-to-print";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
import { getUser } from "actions/Auth";
import { filterStudentListByAssistant } from "actions/ClassAssistantLayout";
import { updateStudentBulkPaymentByClassAssistant } from "actions/ClassAssistantLayout";
import StudentBulkPaymentReceipt from "components/Reports/StudentBulkPaymentReceipt";
import { getAttendanceCountByClassAssistants } from "actions/ClassAssistantLayout";
import { getVTPaymentSummary } from "actions/ClassAssistantLayout";
import { makeVTPayment } from "actions/ClassAssistantLayout";
import VTPaymentReceipt from "components/Reports/VTPaymentReceipt";

const ClassAssistantStudentPayments = () => {
  const receiptRef = useRef();
  const bulkReceiptRef = useRef();
  const vtReceiptRef = useRef();
  const barcodeInputRef = useRef(null);

  const [selectedTwoMonthsAgo, setSelectedTwoMonthsAgo] = useState(null);
  const [selectedLastMonth, setSelectedLastMonth] = useState(null);
  const [selectedThisMonth, setSelectedThisMonth] = useState(null);
  const [barcode, setBarcode] = useState("");
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [months, setMonths] = useState([]);
  const [studentClasses, setStudentClasses] = useState(null);
  const [groupedData, setGroupedData] = useState([]);
  const [barcodeInput, setBarcodeInput] = useState("");
  const [userId, setUserId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const [selectedFeeType, setSelectedFeeType] = useState({});
  const [student, setStudent] = useState({});
  const [receiptData, setReceiptData] = useState({
    subject: "",
    grade: "",
    year: "",
    teacherName: "",
    invoiceNo: "",
    invoiceDate: "",
    paidMoth: "",
    paidAmount: "",
    studentName: "",
    barcode: "",
    payType: "",
  });

  const [bulkReceiptData, setBulkReceiptData] = useState({
    invoiceNo: "",
    invoiceDate: "",
    studentName: "",
    barcode: "",
    payments: [
      {
        subject: "",
        grade: "",
        year: "",
        teacherName: "",
        paidMonth: "",
        paidAmount: "",
        payType: ""
      }
    ]
  });

  const [vtReceiptData, setVTReceiptData] = useState({
    subject: "",
    invoiceNo: "",
    invoiceDate: "",
    totalAmount: "",
    totalPaidAmount: "",
    paidAmount: "",
    studentName: "",
    barcode: "",
    dueAmount: "",
  });

  const [conditionAmount, setConditionAmount] = useState(null);

  const fetchData = async () => {
    const response = await getUser();
    setUserId(response.id);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    let newDate = `${year}-${month}`;
    let prevMonth = month - 1;
    let twoMonthsAgo = month - 2;

    if (month === 1) {
      prevMonth = `${year - 1}-12`;
      twoMonthsAgo = `${year - 1}-11`;
    } else if (month === 2) {
      prevMonth = `${year}-1`;
      twoMonthsAgo = `${year - 1}-12`;
    } else {
      prevMonth = `${year}-${prevMonth}`;
      twoMonthsAgo = `${year}-${twoMonthsAgo}`;
    }
    setMonths([twoMonthsAgo, prevMonth, newDate]);
  };

  const groupByTeacherGradeSubject = async (data) => {
    setConditionAmount(data.class_fee_condition.price);
    const grouped = data.class_fee.reduce((acc, item) => {
      const { classUserId, teacher, subject, grade, price, user_fee_type, cu_status } =
        item;
      const key = `${classUserId}`;

      if (!acc[key]) {
        acc[key] = {
          teacherName: teacher,
          subjectName: subject,
          gradeName: grade,
          classFee: data.class_fee_condition.price < price ? data.class_fee_condition.price : price,
          feeType: user_fee_type,
          cuStatus: cu_status,
          items: [],
        };
      }

      acc[key].items.push(item);
      return acc;
    }, {});
    const result = Object.keys(grouped).map((key) => ({
      classUserId: key,
      teacherName: grouped[key].teacherName,
      subjectName: grouped[key].subjectName,
      gradeName: grouped[key].gradeName,
      classFee: grouped[key].classFee,
      feeType: grouped[key].feeType,
      cuStatus: grouped[key].cuStatus,
      items: grouped[key].items,
    }));
    setGroupedData(result);
  };

  let barcodeString = "";

  const handleKeyDown = async (event) => {
    const barcodePattern = /^[0-9]$/;
    if (barcodePattern.test(event.key)) {
      barcodeString += event.key;
      if (barcodeString.length === 7) {
        barcodeInputRef.current.value = barcodeString;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [isInactive, setInactive] = useState(false);
  const [inactiveMsg, setInactiveMsg] = useState(null);
  const [isVTStudent, setVTStudent] = useState(null);
  const [vtPayDetails, setVTPayDetails] = useState({});
  const [paidTotal, setPaidTotal] = useState(0);
  const [dueBalance, setDueBalance] = useState(0);

  const getStudentPay = async (barcode) => {
    setVTPayDetails({})
    if (barcode.length <= 7) {
      setBarcodeInput(barcode);
    } else if (barcode.length > 7) {
      barcode = barcode.slice(7);
      setBarcodeInput(barcode);
    }
    setBarcode(barcode);
    try {
      if (barcode.length === 7) {
        const studentDetails = await getStudentByClassAssistants(barcode);
        if (studentDetails.success === true) {
          setInactive(false);
          setInactiveMsg(null);
          setStudent(studentDetails.students);
          if (studentDetails.students.user_type === 'IT_SCHOOL') {
            const response = await getLastThreeMonthsFeeByClassAssistants({
              barcode: barcode,
              months: months,
            });
            if (response.class_fee.length > 0) {
              setNullMessage(false);
              setStudentClasses(response);
              setTotalAmount(0);
              await groupByTeacherGradeSubject(response);
            } else {
              setStudentClasses(null);
              setNullMessage(true);
              setTotalAmount(0);
            }
          } else if (studentDetails.students.user_type === 'VOCATIONAL_TRAINING' && studentDetails.students.class_users.length > 0) {
            setVisibleOptionIndex(null);
            setVtError('');
            setPayAmount(null);
            setVTStudent(studentDetails.students.class_users);
          }
        } else {
          setNullMessage(false);
          setInactive(true);
          setInactiveMsg('This student is not active.')
        }
      } else {
        setStudentClasses(null);
      }
    } catch (error) {
      setStudentClasses(null);
    }
  };

  const [isVtPayLoading, setVtPayLoading] = useState(false);

  const getVTPayDetails = async (details) => {
    try {
      setVtPayLoading(true);
      const response = await getVTPaymentSummary({
        barcode: barcode,
        teacherClassId: +(details.teacher_class.id),
      });
      if (response.vt_payments.length > 0) {
        setVtPayLoading(false);
        const paidTotal = response.vt_payments.reduce((total, payment) => {
          return total + (payment.amount || 0);
        }, 0);
        setPaidTotal(paidTotal);
        setDueBalance(details.teacher_class.price - paidTotal);
        setVTPayDetails(response);
      }
    } catch (error) {
      setVTPayDetails({});
      setVtPayLoading(false);
    }
  }

  const handleInputChange = async (event) => {
    setTotalAmount(0);
    setBulkPayments([]);
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      setBarcodeInput(value);
      if (name === "barcode") {
        await getStudentPay(value);
      }
    }
  };

  const resetSelections = () => {
    setSelectedTwoMonthsAgo(null);
    setSelectedLastMonth(null);
    setSelectedThisMonth(null);
    setStudentClasses(null);
    setGroupedData([]);
    setStudent({ registration_number: "" });
    setVTStudent(null);
    setPayAmount(null);
    setBarcodeInput("");
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const handleBulkPrint = useReactToPrint({
    content: () => bulkReceiptRef.current,
  });

  const handleVTPrint = useReactToPrint({
    content: () => vtReceiptRef.current,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const [selectedClass, setSelectedClass] = useState(null);

  const [classFees, setClassFees] = useState({
    update_class_fees: []
  });

  const [isBulkPay, setBulkPay] = useState(false);

  const makeAPayment = async (details) => {
    try {
      setBulkPay(false);
      if (details.amount !== null) {
        setSelectedClass(details);
      } else {
        const currentDate = new Date();
        const previousMonthDate = new Date(currentDate);
        const month = currentDate.getMonth() + 1;
        const todayDate = String(currentDate.getDate()).padStart(2, "0");
        let preMonth = new Date(currentDate);
        if (month !== details.month) {
          preMonth = new Date(details.year, details.month - 1, 15);
        } else {
          preMonth = new Date(details.year, details.month - 1, todayDate);
        }
        const formatedDate = dateFormat(preMonth, "yyyy-mm-dd h:MM:ss");

        let typeVar;
        if (Object.keys(selectedFeeType).length !== 0) {
          const [[key, value]] = Object.entries(selectedFeeType);

          if (key === `${details.classUserId}-${details.month}`) {
            typeVar = value;
          } else {
            typeVar = details.user_fee_type;
          }
        } else {
          typeVar = details.user_fee_type;
        }

        const payDetails = {
          month: details.month,
          year: details.year,
          classUserId: +details.classUserId,
          paymentType: details.payment_type,
          feeType: typeVar,
          date: formatedDate,
          paid_date: dateFormat(currentDate, "yyyy-mm-dd h:MM:ss"),
          userId: +userId,
          amount: details.price < conditionAmount ? details.price : conditionAmount,
        };

        setClassFees((prevState) => ({
          ...prevState,
          update_class_fees: [...prevState.update_class_fees, payDetails]
        }));
        const response = await updateStudentPaymentByClassAssistant(payDetails);
        setReceiptData({
          teacherName: details.teacher,
          subject: details.subject,
          grade: details.grade,
          year: details.year,
          month: details.month,
          barcode: student.barcode,
          studentName: student.full_name,
          invoiceNo: response.data.invoice_number,
          paidAmount: response.data.amount,
          invoiceDate: currentDate,
          payType: response.data.fee_type,
        });
        if (response.success === true) {
          await getStudentPay(barcode);
          handlePrint();
        }
      }
    } catch (error) {
    }
  };

  const [visibleOptionIndex, setVisibleOptionIndex] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(null);

  const handleInputChange1 = async (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    if (searchTerm.length >= 3) {
      const response = await filterStudentListByAssistant(searchTerm);
      const filteredItems = response.data.data.filter((user) =>
        user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filteredItems);
    }
  };

  const [nullMessage, setNullMessage] = useState(false);
  const getStudentBySeacrh = async (student) => {
    try {
      setNullMessage(false);
      setFilteredUsers(null);
      const studentDetails = await getStudentByClassAssistants(student);
      setStudent(studentDetails.students);
      setBarcodeInput(student);
      setBarcode(student);
      if (studentDetails.students.user_type === 'IT_SCHOOL') {
        const response = await getLastThreeMonthsFeeByClassAssistants({
          barcode: student,
          months: months,
        });
        if (response.class_fee.length > 0) {
          setStudentClasses(response);
          setTotalAmount(0);
          setBulkPayments([]);
          await groupByTeacherGradeSubject(response);
        } else {
          setNullMessage(true);
          setStudentClasses(null);
          setTotalAmount(0);
          setBulkPayments([]);
        }
      } else if (studentDetails.students.user_type === 'VOCATIONAL_TRAINING' && studentDetails.students.class_users.length > 0) {
        setVTStudent(studentDetails.students.class_users);
      }
    } catch (error) { }
  };

  const [searchbar, setSearchBar] = useState(false);
  const [bulkPayments, setBulkPayments] = useState([]);

  const handleRadioChange = (key, feeType) => {
    setSelectedFeeType((prev) => ({
      ...prev,
      [key]: feeType
    }));

    // Update fee type in bulk payment if the class is already added
    setBulkPayments((prev) => {
      const updatedPayments = prev.map((item) =>
        item.key === key ? { ...item, feeType } : item
      );
      calculateTotalAmount(updatedPayments);
      return updatedPayments;
    });
  };

  const calculateTotalAmount = (payments) => {
    const total = payments.reduce((sum, item) => {
      const feeType = item.feeType;
      const classFee = conditionAmount < item.classDetails.price ? conditionAmount : item.classDetails.price;
      if (feeType === "FULL") {
        return sum + classFee;
      } else if (feeType === "HALF_FREE") {
        return sum + (classFee / 2);
      } else {
        return sum;
      }
    }, 0);
    setTotalAmount(total);
  };

  const addAPayment = (classDetails, isChecked) => {
    const key = `${classDetails.classUserId}-${classDetails.month}`;

    if (isChecked) {
      setBulkPayments((prev) => {
        const updatedPayments = [
          ...prev,
          { key, classDetails, feeType: selectedFeeType[key] || "FULL" }
        ];
        calculateTotalAmount(updatedPayments);
        return updatedPayments;
      });
    } else {
      setBulkPayments((prev) => {
        const updatedPayments = prev.filter(item => item.key !== key);
        calculateTotalAmount(updatedPayments);
        return updatedPayments;
      });
    }
  };

  const submitBulkPayment = async () => {
    try {
      setBulkPay(true);
      const formatedDate = dateFormat(new Date(), "yyyy-mm-dd h:MM:ss");
      const currentDate = new Date();
      const updateClassFees = bulkPayments.map(details => ({
        classUserId: details.classDetails.classUserId,
        month: details.classDetails.month,
        year: details.classDetails.year,
        feeType: details.feeType,
        date: formatedDate,
        paid_date: dateFormat(currentDate, "yyyy-mm-dd h:MM:ss"),
        userId: +userId,
        paymentType: details.classDetails.payment_type,
        amount: conditionAmount < details.classDetails.price ? conditionAmount : details.classDetails.price
      }));

      const payload = {
        update_class_fees: updateClassFees
      };
      const response = await updateStudentBulkPaymentByClassAssistant(payload);
      setBulkReceiptData({
        barcode: student.barcode,
        studentName: student.full_name,
        payments: response.class_fees.map((details) => ({
          teacherName: details.class_fee.class_user.teacher_class.teacher.full_name || 'N/A',
          subject: details.class_fee.class_user.teacher_class.subject.name || 'N/A',
          grade: details.class_fee.class_user.teacher_class.grade.name,
          year: details.class_fee.year,
          month: details.class_fee.month,
          paidAmount: details.class_fee.amount,
          invoiceDate: currentDate,
          payType: details.class_fee.fee_type,
          invoiceNo: details.class_fee.invoice_number,
        }))
      });
      if (response.success === true) {
        await getStudentPay(barcode);
        setTotalAmount(0);
        setBulkPayments([]);
        handleBulkPrint();
      } else {
        setTotalAmount(0);
        setBulkPayments([]);
      }
    } catch (error) {
    }
  };

  const [isPopover, setPopover] = useState(false);
  const [attendance, setAttendance] = useState([]);

  const getAttendanceCount = async (details) => {
    try {
      const response = await getAttendanceCountByClassAssistants(details[0], details[1]);
      if (response.length > 0) {
        setPopover(true);
        setAttendance(response);
      } else {
        setPopover(false);
      }
    } catch (error) {
      setPopover(false);
    }
  }

  const [payAmount, setPayAmount] = useState(null);
  const [vtError, setVtError] = useState('');
  const [vtBtnDisabled, setVtBtnDisable] = useState(false);

  // Validate input
  const validateVTPayment = () => {
    if (payAmount <= 0) {
      setVtError("Amount is Required!");
      return false;
    } else {
      setVtError("");
      return true;
    }
  };

  const makeAVTPayment = async (details) => {
    try {
      if (validateVTPayment()) {
        setVtBtnDisable(true);
        const formatedDate = dateFormat(new Date(), "yyyy-mm-dd");
        const response = await makeVTPayment({
          class_user_id: +details,
          staff_id: +userId,
          amount: +payAmount,
          date: formatedDate
        });
        setVTReceiptData({
          subject: response.invoice.class_user.teacher_class.subject.name,
          barcode: student.barcode,
          studentName: student.full_name,
          invoiceNo: response.invoice.invoice_number,
          paidAmount: response.invoice.amount,
          invoiceDate: formatedDate,
          totalPaidAmount: (paidTotal + response.invoice.amount),
          dueAmount: (response.invoice.class_user.teacher_class.price - (paidTotal + response.invoice.amount)),
          totalAmount: response.invoice.class_user.teacher_class.price,
        });
        if (response.success === true) {
          setPayAmount(null);
          await getVTPayDetails(response.invoice.class_user);
          handleVTPrint();
          setVtBtnDisable(false);
        }
      }
    } catch (error) {
      setVtError(error.message);
      setVtBtnDisable(false);
    }
  }

  return (
    <>
      <div className="mt--3 container-fluid assistant-container">
        <Card className="mt-4 mb-4 pb-4 shadow">
          <CardHeader className="border-0 assistantStudentHeader">
            <div
              className="headerLeft"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 className="mb-0 mr-2">Student Payments</h3>
              <i
                class="fa-solid fa-magnifying-glass"
                style={{ cursor: "pointer" }}
                onClick={() => setSearchBar(!searchbar)}
              ></i>
            </div>
          </CardHeader>
          {searchbar ? (
            <div className="ml-2">
              <Col md="4">
                <FormGroup>
                  <Input
                    type="text"
                    value={searchItem}
                    onChange={handleInputChange1}
                    placeholder="Search..."
                    style={{
                      borderBottomLeftRadius: searchItem !== "" ? "0px" : "",
                      borderBottomRightRadius: searchItem !== "" ? "0px" : "",
                    }}
                  />
                  {searchItem && (
                    <ul
                      style={{
                        position: "absolute",
                        width: "94%",
                        listStyleType: "none",
                        padding: "0",
                        border: "1px solid #ddd",
                        zIndex: 10,
                        backgroundColor: "white",
                        display: filteredUsers === null ? "none" : "",
                      }}
                    >
                      {filteredUsers?.map((option, index) => (
                        <li
                          key={index}
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                            cursor: "pointer",
                          }}
                          onClick={() => getStudentBySeacrh(option.barcode)}
                        >
                          {option.full_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </FormGroup>
              </Col>
            </div>
          ) : null}
          <div className="ml-4 barcodeSearch">
            <FormGroup>
              <Input
                id="barcode"
                placeholder="Barcode"
                type="text"
                value={barcodeInput}
                onChange={handleInputChange}
                ref={barcodeInputRef}
                name="barcode"
                pattern="\d*"
                className="barcodeSearchInput"
                autoFocus
              />
            </FormGroup>
            <FormGroup>
              <Input
                id="student_id"
                placeholder="Student Id"
                type="text"
                value={student?.registration_number}
                name="student_id"
                disabled
                className="barcodeSearchInput"
              />
            </FormGroup>
            <FormGroup>
              <Button onClick={resetSelections} color="primary">
                Reset
              </Button>
              {!isBulkPay ?
                <Button onClick={handlePrint} color="primary">
                  Last Receipt
                </Button> :
                <Button onClick={handleBulkPrint} color="primary">
                  Last Receipt
                </Button>
              }
            </FormGroup>
          </div>
          {nullMessage ?
            <div style={{ paddingLeft: "20px" }}>
              {student.full_name} has not enrolled to any class yet!
            </div>
            : null}
          {student.user_type === 'IT_SCHOOL' ?
            <div className="classList">
              <div className="bodySection">
                <div className="classPaymentOptions">
                  {studentClasses !== null ? (
                    groupedData.map((studentClass, classIndex) => (
                      studentClass.cuStatus === 1 ?
                        <Form key={classIndex} className="ml-4 mb-2 assistantStudentPayments">
                          <div className="classDetails">
                            <h3>{studentClass.teacherName}</h3>
                            <p>{studentClass.subjectName} - {studentClass.gradeName}</p>
                            <p>Rs.{studentClass.classFee}.00</p>
                            <p style={{ color: "green" }}>{studentClass.feeType}</p>
                          </div>
                          <div className="payHistory">
                            {studentClass.items.map((classes, index) => {
                              const key = `${classes.classUserId}-${classes.month}`;

                              return (
                                <div className="paymentOptions" key={index}>
                                  <FormGroup tag="fieldset" className="fieldSetPayOptions">
                                    <Label style={{ fontWeight: "bold" }}>
                                      <i className="fa-solid fa-calendar-days mr-2" id="PopoverLegacy"
                                        type="button" onClick={() => getAttendanceCount([studentClass.classUserId, (classes.year + '-' + classes.month.toString().padStart(2, "0"))])}></i>
                                      {classes.year}-{classes.month.toString().padStart(2, "0")}
                                    </Label>
                                    <UncontrolledPopover
                                      placement="right"
                                      target="PopoverLegacy"
                                      trigger="legacy"
                                    >
                                      <PopoverBody>
                                        <h4>
                                          Dates Attended
                                        </h4>
                                        {isPopover ?
                                          attendance?.map((att) => (
                                            <li key={att.id} className="ml-2">{(att.date).split(' ')[0]}</li>
                                          )) : 'None'}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                    {classes.amount === null && (
                                      <Input
                                        type="checkbox"
                                        className="ml-2 large-checkbox"
                                        checked={bulkPayments.some(item => item.key === key)} // ensure checkbox reflects the state
                                        onChange={(e) => addAPayment(classes, e.target.checked)}
                                        style={{ position: 'relative' }}
                                      />
                                    )}
                                    <div
                                      className="radioOptions"
                                      style={{ display: visibleOptionIndex === classIndex ? "" : "none" }}
                                    >
                                      <FormGroup check>
                                        <Input
                                          name={key}
                                          type="radio"
                                          checked={
                                            selectedFeeType[key] === "FULL"
                                          }
                                          onChange={() => handleRadioChange(key, "FULL")}
                                          disabled={classes.amount !== null}
                                        />
                                        <Label check>Full</Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Input
                                          name={key}
                                          type="radio"
                                          checked={
                                            selectedFeeType[key] === "HALF_FREE"
                                          }
                                          onChange={() => handleRadioChange(key, "HALF_FREE")}
                                          disabled={classes.amount !== null}
                                        />
                                        <Label check>Half</Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Input
                                          name={key}
                                          type="radio"
                                          checked={
                                            selectedFeeType[key] === "FULL_FREE"
                                          }
                                          onChange={() => handleRadioChange(key, "FULL_FREE")}
                                          disabled={classes.amount !== null}
                                        />
                                        <Label check>Free</Label>
                                      </FormGroup>
                                    </div>
                                  </FormGroup>
                                  <div className="paymentBtn">
                                    <Button
                                      className={classes.amount !== null ? "paidBtn" : "unpaidBtn"}
                                      onClick={() => makeAPayment(classes)}
                                      disabled={classes.amount !== null}
                                    >
                                      {classes.amount !== null ? `Paid` : `Pay`}
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="visiblePayOptions">
                            {visibleOptionIndex === classIndex ? (
                              <SlArrowDown onClick={() => setVisibleOptionIndex(null)} />
                            ) : (
                              <SlArrowUp onClick={() => setVisibleOptionIndex(classIndex)} />
                            )}
                          </div>
                        </Form> : null
                    ))
                  ) : (
                    null
                  )}
                </div>
                {studentClasses !== null ?
                  <FormGroup className="mt-4">
                    <Row className="ml-4">
                      <Button onClick={submitBulkPayment} color="primary">
                        Bulk Payment
                      </Button>
                      <h3 className="mt-2">Total Amount: Rs.{totalAmount}.00</h3>
                    </Row>
                  </FormGroup> : null}
              </div>
              {studentClasses !== null ? (
                <div className="studentSection">
                  <div className="profile-card">
                    <div className="imageStudent">
                      <img src={student.image_url ? student.image_url : 'https://nenasala-balangoda.ardillalabs.cloud/api/uploads/person-icon.jpg'} className="studentPaymentImage" />
                    </div>
                    <div className="studentDetailsClassAssistant">
                      <h3><i class="fa-solid fa-circle-user"></i> {student.full_name}</h3>
                      <p style={{ fontSize: "12px" }}><i class="fa-solid fa-registered"></i> {student.registration_number}</p>
                      {student.phone_number !== null ? <p><i class="fa-solid fa-phone-volume"></i> {student.phone_number}</p> : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div> :
            <div className="classList">
              <div className="bodySection">
                <div className="examPaymentOptions">
                  {isVTStudent !== null ? (
                    student?.class_users?.map((studentClass) => (
                      <Form key={studentClass.id} className="ml-4 mb-2 assistantStudentExamPayments">
                        <div className="classExDetails">
                          <div className="classLeft">
                            <h3>{studentClass.teacher_class.subject.name}</h3>
                            <h3>Rs.{studentClass.teacher_class.price}.00</h3>
                          </div>
                          {visibleOptionIndex === studentClass.id ? (
                            <div className="visibleExamPayOptions" onClick={() => setVisibleOptionIndex(null)}>
                              <SlArrowDown />
                            </div>
                          ) : (
                            <div className="visibleExamPayOptions" onClick={() => { getVTPayDetails(studentClass); setVisibleOptionIndex(studentClass.id) }}>
                              <SlArrowUp />
                            </div>
                          )}
                        </div>
                        <div className="examHistory" style={{ display: visibleOptionIndex === studentClass.id ? "" : "none" }}>
                          <Label>Paid Total:  {!isVtPayLoading && `Rs.${paidTotal}.00`}</Label>
                          <Label>Due Balance:  {!isVtPayLoading && `Rs.${dueBalance}.00`}</Label>
                          <Table className="align-items-center" responsive style={{ width: '400px' }}>
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col" style={{ textAlign: 'right' }}>Amount</th>
                              </tr>
                            </thead>
                            <tbody style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                              {isVtPayLoading ? (
                                <tr>
                                  <td colSpan="2" style={{ textAlign: 'center' }}>
                                    <Spinner className="m-10">Loading...</Spinner>
                                  </td>
                                </tr>
                              ) : (
                                vtPayDetails?.vt_payments?.map((classUser, index) => (
                                  <tr key={index}>
                                    <td>{classUser.date}</td>
                                    <td style={{ textAlign: 'right' }}>
                                      {classUser.amount ? `Rs.${classUser.amount}.00` : null}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </Table>
                          <div className="paymentExOptions">
                            <FormGroup tag="fieldset" className="fieldSetExamPayOptions">
                              <Label style={{ fontWeight: "bold", marginBottom: "0px" }}>
                                Amount
                              </Label>
                            </FormGroup>
                            <FormGroup tag="fieldset" className="fieldSetExamPayOptions">
                              <Input
                                type="text"
                                id="vtAmount"
                                name="vtAmount"
                                onChange={(e) => {
                                  if (/^\d*$/.test(e.target.value)) {
                                    setPayAmount(e.target.value);
                                  };
                                  setVtError('');
                                }}
                                value={payAmount}
                              />
                              {vtError && (
                                <p className="text-danger">{vtError}</p>
                              )}
                            </FormGroup>
                            <div className="paymentBtn">
                              <FormGroup>
                                <Button
                                  className={"unpaidBtn"}
                                  onClick={() => makeAVTPayment(studentClass.id)}
                                  disabled={vtBtnDisabled}
                                >
                                  Pay
                                </Button>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </Form>
                    ))
                  ) : null}
                </div>
              </div>
              {isVTStudent !== null ? (
                <div className="studentSection">
                  <div className="profile-card">
                    <div className="imageStudent">
                      <img src={student.image_url ? student.image_url : 'https://nenasala-balangoda.ardillalabs.cloud/api/uploads/person-icon.jpg'} className="studentPaymentImage" />
                    </div>
                    <div className="studentDetailsClassAssistant">
                      <h3><i class="fa-solid fa-circle-user"></i> {student.full_name}</h3>
                      <p style={{ fontSize: "12px" }}><i class="fa-solid fa-registered"></i> {student.registration_number}</p>
                      {student.phone_number !== null ? <p><i class="fa-solid fa-phone-volume"></i> {student.phone_number}</p> : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>}
        </Card>
        <div style={{ display: "none" }}>
          <StudentPaymentReceipt ref={receiptRef} allDetails={receiptData} />
        </div>
        <div style={{ display: "none" }}>
          <StudentBulkPaymentReceipt ref={bulkReceiptRef} allDetails={bulkReceiptData} />
        </div>
        <div style={{ display: "none" }}>
          <VTPaymentReceipt ref={vtReceiptRef} allDetails={vtReceiptData} />
        </div>
      </div>
    </>
  );
};

export default ClassAssistantStudentPayments;